import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import scrollToElement from 'scroll-to-element'
import slugify from 'slugify'

import ThemeContext from '../../context/ThemeContext'
import { SLUGIFY_OPTIONS } from '../../utils/constants'
import { getFluidImage } from '../../utils/images'
import Modal from '../Modal'
import HubSpotForm from '../HubSpotForm'
import Form from '../Form' // fallback
import {
  getComponentClass,
  getComponentId,
  getDestinationLabel
} from '../../utils/helpers'

const Specialists = ({ settings, pageContext }) => {
  const {
    specialists,
    groups,
    destination,
    form,
    hideFormDescription
  } = settings
  const { langSlug, isRootPage, knechtDestination, url, hsForms } = pageContext

  const currentPageUrl = url || ''
  const mainDestinationMenuItems = knechtDestination?.menuItems?.value || []
  const destinationMenuItems = pageContext?.destination?.menuItems?.value || []
  const currentDestinationName = pageContext?.destination?.name?.value || ''

  const isHubSpotForm = form?.snippet && form.snippet ? true : false // fallback

  const componentId = getComponentId(settings)

  const { allCockpitDestinations } = useStaticQuery(
    graphql`
      query {
        allCockpitDestinations {
          nodes {
            cockpitId
            isActive {
              value
            }
            name {
              value
            }
            slug {
              value
            }
            specialistsDestinationName {
              value
            }
          }
        }
      }
    `
  )

  const processedDestinations = allCockpitDestinations?.nodes.map(dest => {
    return {
      // cockpitId: dest?.cockpitId,
      isActive: dest?.isActive?.value,
      name: dest?.name?.value,
      // slug: dest?.slug?.value,
      specialistsDestinationName: dest?.specialistsDestinationName?.value,
    }
  })

  const [isOpen, setIsOpen] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [currentSpecialist, setCurrentSpecialist] = useState(null)
  const destinationEmail =
    currentSpecialist?.destination?.value.Destination.value.emailFormSubmissions
      .value
  const noDescFormDestinations = [
    'sport',
    'trainingslager',
    'golf',
    'sportlive'
  ]
  if (
    noDescFormDestinations.includes(currentDestinationName) &&
    form?.name === 'Spezialisten'
  ) {
    // do not display form description on the Sportreisen Specialists form
    form.desc = ''
  }

  const toggleModal = specialist => {
    setModalIsOpen(!modalIsOpen)
    setCurrentSpecialist(specialist)
  }

  const scrollToGroup = groupName => {
    const groupSlug = slugify(groupName, SLUGIFY_OPTIONS)
    scrollToElement(`#${groupSlug}`, { offset: -220 })
  }

  const renderSpecialistsGroup = (groupName, color) => (
    <>
      <div className='specialists-group-title'>
        <strong>Unser Spezialisten-Team {getGroupLabel(groupName)}</strong>
      </div>

      {specialists[groupName]?.map((specialist, index) => {
        const image = specialist.picture?.value?.[0]

        let text = specialist.destination.value?.Text?.value

        // Search for links and add langSlug with last /
        if (langSlug && text) {
          text = text.replace(/ href="\//g, ' href="/' + langSlug)
        }

        return (
          <div key={index} className='specialist-item row'>
            <div className='col-sm-4 specialist-item-contact'>
              <div className='mobile-content-wrapper'>
                <Img
                  fluid={getFluidImage({
                    cloudinary: image?.public_id,
                    ar: 1.6
                  })}
                  alt={image?.meta?.description || 'specialists destination'}
                  title={image?.meta?.description}
                  className='specialist-image'
                  loading='lazy'
                  placeholderStyle={{ backgroundColor: 'lightgray' }}
                />

                <div className='specialist-name'>{specialist.name?.value}</div>
                <div className='specialist-number'>
                  {specialist.phonenumber?.value}
                </div>

                {specialist.email && (
                  <div>
                    <button
                      className='btn-fill btn-fill mt-2'
                      onClick={() => toggleModal(specialist)}
                      style={{ background: color }}
                    >
                      E-Mail
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className='col-sm-8 specialist-item-text'>
              <div
                dangerouslySetInnerHTML={{
                  __html: text
                }}
              />
            </div>
          </div>
        )
      })}
    </>
  )

  const removeDuplicates = array => {
    let unique = array.reduce((acc, curr) => {
      if (!acc.includes(curr)) acc.push(curr)
      return acc
    }, [])
    return unique
  }

  const renderFilterDropdown = color => (
    <div className='specialists-filter-wrapper'>
      <div className='specialists-filter' style={{ borderColor: color }}>
        <button
          className='specialists-filter-title'
          onClick={() => setIsOpen(!isOpen)}
          style={{ color }}
        >
          <strong>
            Spezialist nach {`${destination ? 'Region' : 'Destination'}`} wählen
          </strong>
          <i className={`ki ki-angle-${isOpen ? 'up' : 'down'}`}></i>
        </button>
        <div className={`specialists-filter-options ${isOpen ? 'open' : ' '}`}>
          {removeDuplicates(groups)
            .sort()
            .map(groupName => {
              if (isGroupActive(groupName)) {
                return (
                <button
                  className='specialists-filter-options-item'
                  key={groupName}
                  onClick={() => scrollToGroup(groupName)}
                >
                  {getGroupLabel(groupName)}
                </button>
               )
              }
            })}
        </div>
      </div>
    </div>
  )

  const getDestinationName = () => {
    return getDestinationLabel(
      currentPageUrl,
      isRootPage,
      mainDestinationMenuItems,
      destinationMenuItems
    )
  }

  const isGroupActive = groupName => {
    const dests = processedDestinations.filter(dest => {
      return dest?.name === groupName
    })

    if (dests && dests.length > 0) {
      return dests[0]?.isActive
    }

    return false
  }

  const getGroupLabel = groupName => {
    const dests = processedDestinations.filter(dest => {
      return dest?.name === groupName
    })

    let label = groupName
    if (dests && dests.length > 0) {
      label = dests[0]?.specialistsDestinationName || dests[0]?.name
    }

    return capitalizeFirstLetter(label)
  }

  const capitalizeFirstLetter = string => {
    if (!string) return ''
    return string.charAt(0).toUpperCase() + string.slice(1)
}

  return (
    <ThemeContext.Consumer>
      {color => (
        <div
          id={componentId}
          className={`specialists specialists-destination ${getComponentClass(
            settings
          )}`}
        >
          <Modal modalIsOpen={modalIsOpen} toggleModal={toggleModal}>
            {isHubSpotForm && (
              <HubSpotForm
                settings={form}
                additionalSettings={{
                  destination: pageContext?.destination,
                  hideFormDescription: hideFormDescription
                }}
                defaultValues={{
                  recipients: [
                    currentSpecialist?.email?.value,
                    destinationEmail
                  ].join(','),
                  destinationlabel: getDestinationName(),
                  specialistname: currentSpecialist?.name?.value
                }}
                stringVars={{
                  header: currentSpecialist?.name?.value,
                  // subHeader: currentSpecialist?.email?.value,
                  subHeader: '', // hide email address
                  successMessage: currentSpecialist?.name?.value
                }}
                allHsForms={hsForms}
              />
            )}
            {!isHubSpotForm && (
              <Form
                settings={{
                  formData: form,
                  destination: pageContext?.destination
                }}
                defaultValues={{
                  recipients: [
                    currentSpecialist?.email?.value,
                    destinationEmail
                  ].join(','),
                  destinationLabel: getDestinationName(),
                  specialistName: currentSpecialist?.name?.value
                }}
                stringVars={{
                  header: currentSpecialist?.name?.value,
                  // subHeader: currentSpecialist?.email?.value,
                  subHeader: '', // hide email address
                  successMessage: currentSpecialist?.name?.value
                }}
              />
            )}
          </Modal>

          <div className='specialists-groups'>
            {removeDuplicates(groups)
              .sort()
              .map((groupName, index) => (
                <div
                  key={groupName}
                  id={slugify(groupName, SLUGIFY_OPTIONS)}
                  className='specialists-group'
                >
                  {index === 0 &&
                    groups.length > 1 &&
                    renderFilterDropdown(color)}

                  {isGroupActive(groupName) && renderSpecialistsGroup(groupName, color)}
                </div>
              ))}

            {/* specialists outside of group */}
            {specialists.undefined && (
              <div className='specialists-group'>
                {renderSpecialistsGroup(undefined, color)}
              </div>
            )}
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default Specialists
